<template>
  <div>
    <div class="header">
      <div class="row align-items-center ">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="d-flex justify-content-start">
            <img src="@/assets/logo.svg" />
          </div>
        </div>

        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="d-flex justify-content-end title"><p>kss-rechner</p></div>
        </div>
      </div>
    </div>
    <div class="row subtitle align-items-center">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 subitleColumn">
        <div class="d-flex justify-content-between row" style="width:70%;">
          <div style="display:flex;" class="col-auto">
            <div class="row ">
             
                  <img src="@/assets/settings.png" class="settingsImg" style="width:62px;height:45px;"/>
              
               
          <p class="col-auto" v-if="langDE">die mischung stimmt</p>
          <p class="col-auto" v-if="langHU">A kulcs a megfelelő arány</p>
          <p class="col-auto" v-if="langEN">the mix is ok</p>
            </div>
            
          </div>
          <div style="display:flex;color:black;"  class="col-auto">
            <div style="margin-right:20px;">
              <a href="?lang=de"><img width="30" height="20" src="@/assets/de.svg"></a>
            </div>
            <div style="margin-right:20px;">
              <a href="?lang=hu"><img width="30" height="20" src="@/assets/hu.svg"></a>
            </div>
            <div>
              <a href="?lang=en"><img width="30" height="20" src="@/assets/gb.svg"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 description">
        <p v-if="langDE">
          Die Mischung stimmt! Unsere wassermischbaren Kühlschmierstoffe bieten
          für jede Anwendung ein optimales Produkt mit Basisstoffen bester
          Qualität. Unser KSS-Rechner soll die Arbeit erleichtern und bietet
          Ihnen nach Eingabe Ihrer Parameter die Mengen für die
          <span class="span1">MANUELLE MISCHUNG</span> oder den Prozentwert für
          Ihr <span class="span1">MISCHGERÄT</span> an.
        </p>
        <p v-if="langHU">
          A kulcs a megefelelő arány az emulzióban! Vízzel keverhető
          hűtő-kenőanyagaink minden alkalmazáshoz optimális terméket kínálnak a
          legjobb minőségű alapanyagokkal. A hűtőfolyadék-kalkulátorunk célja,
          hogy megkönnyítse a munkáját! A paraméterek megadása után Önnek csak
          le kell olvasnia a
          <span class="span1">KÉZI KEVERÉS</span> mennyiségeit vagy a
          <span class="span1">KEVERŐ</span> százalékos értékét.
        </p>
        <p v-if="langEN">The mix is right! Our water-miscible cooling lubricants offer an optimal product with base materials of the best quality for every application. Our coolant calculator is intended to make your work easier and, after entering your parameters, offers you the quantities for the
          <span class="span1">MANUAL MIXINGS</span> or the percentage value for your
          <span class="span1">MIXING DEVICE.</span>
        </p>
      </div>
    </div>
    <div class="formDiv">
      <div class="row ">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="inputs col-xl-4">
              <div>
                <span v-if="langDE" id="helpId" class="labelTitle"
                  >Soll-Füllvolumen </span
                >
                <span v-if="langDE" class="labelSpan">(in Litern)</span>
                <span v-if="langHU" id="helpId" class="labelTitle">Ajánlott tartálytöltöttségi szint </span>
                <span v-if="langHU" class="labelSpan">(Literben)</span>
                <span v-if="langEN" id="helpId" class="labelTitle"
                  >Target filling volume
                </span>
                <span v-if="langEN" class="labelSpan">(in liters)</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="firstValue"
                  name=""
                  id=""
                  placeholder="0"
                  v-on:input="valueChanged()"
                  maxlength="7"
                />
              </div>
              <div class="input">
                <span v-if="langDE" id="helpId" class="labelTitle"
                  >Sollkonzentration </span
                >
                <span v-if="langDE" class="labelSpan">(in Prozent)</span>
                <span v-if="langHU" id="helpId" class="labelTitle"
                  >Ajánlott koncentráció </span
                >
                <span v-if="langHU" class="labelSpan">(Százalékban)</span>
                <span v-if="langEN" id="helpId" class="labelTitle"
                  >Target concentration </span
                >
                <span v-if="langEN" class="labelSpan">(in percent)</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="secondValue"
                  name=""
                  id=""
                  placeholder="0"
                  v-on:input="valueChanged()"
                  maxlength="5"
                />
              </div>
              <div class="input">
                <span v-if="langDE" id="helpId" class="labelTitle"
                  >Istvolumen </span
                >
                <span v-if="langDE" class="labelSpan">(in Litern)</span>
                <span v-if="langHU" id="helpId" class="labelTitle"
                  >Aktuális tartálytöltöttségi szint </span
                >
                <span v-if="langHU" class="labelSpan">(Literben)</span>
                <span v-if="langEN" id="helpId" class="labelTitle"
                  >Actual volume </span
                >
                <span v-if="langEN" class="labelSpan">(in liters)</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="thirdValue"
                  name=""
                  id=""
                  placeholder="0"
                  v-on:input="valueChanged()"
                  maxlength="7"
                />
              </div>
              <div class="input">
                <span v-if="langDE" id="helpId" class="labelTitle"
                  >Istkonzentration </span
                >
                <span v-if="langDE" class="labelSpan">(in Prozent)</span>
                <span v-if="langHU" id="helpId" class="labelTitle"
                  >Aktuális koncentráció </span
                >
                <span v-if="langHU" class="labelSpan">(Százalékban)</span>
                <span v-if="langEN" id="helpId" class="labelTitle"
                  >Actual concentration </span
                >
                <span v-if="langEN" class="labelSpan">(in percent)</span>
                <input
                  type="text"
                  class="form-control"
                  v-model="fourthValue"
                  name=""
                  id=""
                  placeholder="0"
                  v-on:input="valueChanged()"
                  maxlength="5"
                />
              </div>
            </div>
            <div class="results col-xl-5">
              <div class="row">
                <div class="col col1">
                  <div v-if="langDE" class="row resultTitle marginResultTitle1">
                    Manuelle Mischung
                  </div>
                  <div v-if="langHU" class="row resultTitle marginResultTitle1">
                    Kézi adagolás
                  </div>
                  <div v-if="langEN" class="row resultTitle marginResultTitle1">
                    Manual mix
                  </div>
                  <div class="row marginResultSubtitle"></div>
                  <div class="row marginResultResult resultTextManual">
                    <div class="col-md" v-if="lWater == 0 && langDE">
                      <div>Wasser</div>
                      <span style="color:#9B9B9B;" class="resultNumbers">{{
                        lWater.toFixed(2).toString().replace(".",",")
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                    <div class="col-md" v-if="lWater != 0 && langDE">
                      <div>Wasser</div>
                      <span style="color:#1e1e1e;" class="resultNumbers">{{
                        lWater.toFixed(2).toString().replace(".",",")
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                    <div class="col-md" v-if="lWater == 0 && langEN">
                      <div>Water</div>
                      <span style="color:#9B9B9B;" class="resultNumbers">{{
                        lWater.toFixed(2)
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                    <div class="col-md" v-if="lWater != 0 && langEN">
                      <div>Water</div>
                      <span style="color:#1e1e1e;" class="resultNumbers">{{
                        lWater.toFixed(2)
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                    <div class="col-md" v-if="lWater == 0 && langHU">
                      <div>Víz</div>
                      <span style="color:#9B9B9B;" class="resultNumbers">{{
                        lWater.toFixed(2).toString().replace(".",",")
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                    <div class="col-md" v-if="lWater != 0 && langHU">
                      <div>Víz</div>
                      <span style="color:#1e1e1e;" class="resultNumbers">{{
                        lWater.toFixed(2).toString().replace(".",",")
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                    <div style="width:20px;"></div>
                    <div v-if="lWater == 0 && langDE" class="col-md">
                      <div>Konzentrat</div>
                      <span style="color:#9B9B9B;" class="resultNumbers">{{
                        lKonzetrat.toFixed(2).toString().replace(".",",")
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                    <div v-if="lWater != 0 && langDE" class="col-md">
                      <div>Konzentrat</div>
                      <span style="color:#1e1e1e;" class="resultNumbers">{{
                        lKonzetrat.toFixed(2).toString().replace(".",",")
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                     <div v-if="lWater == 0 && langEN" class="col-md">
                      <div>Concentrate</div>
                      <span style="color:#9B9B9B;" class="resultNumbers">{{
                        lKonzetrat.toFixed(2)
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                    <div v-if="lWater != 0 && langEN" class="col-md">
                      <div>Concentrate</div>
                      <span style="color:#1e1e1e;" class="resultNumbers">{{
                        lKonzetrat.toFixed(2)
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                    <div v-if="lWater == 0 && langHU" class="col-md">
                      <div>Koncentrátum</div>
                      <span style="color:#9B9B9B;" class="resultNumbers">{{
                        lKonzetrat.toFixed(2).toString().replace(".",",")
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                    <div v-if="lWater != 0 && langHU" class="col-md">
                      <div>Koncentrátum</div>
                      <span style="color:#1e1e1e;" class="resultNumbers">{{
                        lKonzetrat.toFixed(2).toString().replace(".",",")
                      }}</span
                      ><span class="measures">Liter</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="line"></div>
              <div class="row">
                <div class="col col1">
                  <div v-if="langDE" class="row resultTitle">Mischgerät</div>
                  <div v-if="langDE" class="row marginResultSubtitle">
                    Konzentration von…
                  </div>
                  <div v-if="langHU" class="row resultTitle">Keverőgép</div>
                  <div v-if="langHU" class="row marginResultSubtitle">
                    Koncentráció beállítása...
                  </div>
                  <div v-if="langEN" class="row resultTitle">Mixer</div>
                  <div v-if="langEN" class="row marginResultSubtitle">
                    Concentration of...
                  </div>
                  <div class="row marginResultResult">
                    <div v-if="lWater == 0" class="col">
                      <span style="color:#9B9B9B;" class="resultNumbers">{{
                        langHU==true || langDE==true?fVolumen.toFixed(3).toString().replace(".",","):fVolumen.toFixed(3)
                      }}</span
                      ><span class="measures">%</span>
                    </div>
                    <div v-if="lWater != 0" class="col">
                      <span style="color:#1e1e1e;" class="resultNumbers">{{
                          langHU==true || langDE==true?fVolumen.toFixed(3).toString().replace(".",","):fVolumen.toFixed(3)
                      }}</span
                      ><span class="measures">%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row footer">
      <div>Copyright 2021 - Zahradnik GmbH</div>
      <div>
        <a href="">Impressum</a> | <a href="">Datenschutz</a> |
        <a href="">Kontakt</a> | <a href="">AVL</a> | <a href="">Sitemap</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      firstValue: "",
      secondValue: "",
      thirdValue: "",
      fourthValue: "",
      skMenge: 0,
      firstMinusThree: 0,
      ikMenge: 0,
      kDifferenz: 0,
      firstMinusThreeTimesFourPercent: 0,
      fVolumen: 0,
      lWater: 0,
      lKonzetrat: 0,
      hundredMinusFVolumen: 0,
      fVolumenString: "",
      language: window.location.href.split("lang=")[1],
      langDE: false,
      langHU: false,
      langEN: false,
    };
  },
  methods: {
    valueChanged() {
      if(this.langDE || this.langHU){
        this.firstValue = this.firstValue.toString().replace(",",".");
        this.secondValue = this.secondValue.toString().replace(",",".");
        this.thirdValue = this.thirdValue.toString().replace(",",".");
        this.fourthValue = this.fourthValue.toString().replace(",",".");
      }
      this.skMenge = (this.firstValue * this.secondValue) / 100;
      this.firstMinusThree = this.firstValue - this.thirdValue;
      this.ikMenge = (this.thirdValue * this.fourthValue) / 100;
      this.kDifferenz = this.skMenge - this.ikMenge;
      this.firstMinusThreeTimesFourPercent = this.firstMinusThree * 0.04;
      this.fVolumen = (this.kDifferenz * 100) / this.firstMinusThree;
      if (!this.isNumeric(this.fVolumen)) {
        this.fVolumen = 0;
      }
      this.hundredMinusFVolumen = 100 - this.fVolumen;
      this.lWater = (this.firstMinusThree * this.hundredMinusFVolumen) / 100;
      if (!this.isNumeric(this.lWater)) {
        this.lWater = 0;
      }
      this.lKonzetrat = (this.firstMinusThree * this.fVolumen) / 100;
      if (!this.isNumeric(this.lKonzetrat)) {
        this.lKonzetrat = 0;
      }
      if(this.langDE || this.langHU){
        this.firstValue = this.firstValue.toString().replace(".",",");
        this.secondValue = this.secondValue.toString().replace(".",",");
        this.thirdValue = this.thirdValue.toString().replace(".",",");
        this.fourthValue = this.fourthValue.toString().replace(".",",");
      }
    },
    clearInputs() {
      this.firstValue = "";
      this.secondValue = "";
      this.thirdValue = "";
      this.fourthValue = "";
      this.valueChanged();
    },
    isNumeric: function(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
  },
  beforeMount() {
    if (this.language == "de" || this.language==null) {
      this.langDE = true;
    }
    if (this.language == "hu") {
      this.langHU = true;
    }
    if (this.language == "en") {
      this.langEN = true;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.col-md {
  margin: 0;
  padding: 0;
}

.col-xl-4 {
  margin: 0;
  padding: 0;
}

.col-xl-4 {
  margin: 0;
  padding: 0;
}

input {
  max-width: 551px;
}

.col-xl {
  margin: 0;
  padding: 0;
}

@media (min-width: 0px) and (max-width: 979px) {
  .header {
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: #fcdc16;
    padding-inline: 0px;
  }
}

@media (min-width: 979px) {
  .header {
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: #fcdc16;
    padding-inline: 104px;
  }
}

@media (max-width: 1265px) {
  input {
    width: 250px;
  }
}

.title {
  color: #314a96;
  font-size: 41px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
}

.subtitle {
  color: #314a96;
  font-size: 44px;
  font-style: italic;
  text-transform: uppercase;
  font-weight: bold;
}

@media (min-width: 0px) and (max-width: 979px) {
  .subitleColumn {
    margin-top: 81px;
    margin-left: 0px;
  }
}

@media (min-width: 979px) {
  .subitleColumn {
    margin-top: 81px;
    margin-left: 104px;
  }
}

.settingsImg {
  margin-right: 17px;
  width: 50px;
  height: 38px;
  margin-top: 18px;
}

@media (min-width: 0px) and (max-width: 979px) {
  .description {
    margin-left: 0px;
    margin-top: 25px;
    text-align: left;
    padding-right: 28%;
    color: #1e1e1e;
    font-size: 18px;
  }
}

@media (min-width: 979px) {
  .description {
    margin-left: 104px;
    margin-top: 25px;
    text-align: left;
    padding-right: 28%;
    color: #1e1e1e;
    font-size: 18px;
  }
}

.span1 {
  font-weight: bold;
  text-transform: uppercase;
}

@media (min-width: 0px) and (max-width: 979px) {
  .formDiv {
    margin-top: 50px;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}

@media (min-width: 979px) {
  .formDiv {
    margin-top: 50px;
    margin-left: 104px;
    margin-bottom: 150px;
  }
}

.col {
  margin: 0px;
  padding: 0px;
}

.row {
  margin: 0px;
  padding: 0px;
}

.inputs {
  width: 551px;
  margin-right: 62px;
  color: #9b9b9b;
}

.labelTitle {
  color: #1e1e1e;
  font-size: 20px;
  font-weight: bold;
}
.labelSpan {
  color: #1e1e1e;
  font-size: 20px;
}

.input {
  margin-top: 29px;
}

@media (max-width: 1351px) {
  .results {
    background-color: #f3f3f3;
    margin-top: 25px;
  }
}
@media (min-width: 1352px) {
  .results {
    background-color: #f3f3f3;
    max-width: auto;
  }
}

@media (min-width: 1352px) {
  .col1 {
    padding-left: 51px;
  }
}
@media (max-width: 1351px) {
  .col1 {
    padding-left: 0px;
  }
}

.resultTitle {
  color: #314a96;
  font-size: 30px;
  font-style: italic;
  font-weight: bold;
}

.marginResultTitle1 {
  margin-top: 15px;
}

.marginResultSubtitle {
  margin-top: 5px;
  font-size: 20px;
  font-weight: bold;
  color: #1e1e1e;
}

.line {
  background-color: #ffffff;
  color: #ffffff;
  height: 2px;
  margin-top: 20px;
  margin-bottom: 18px;
}

.measures {
  color: #314a96;
  font-size: 20px;
  font-weight: bold;
  margin-left: 11px;
  margin-right: 76px;
}

.resultNumbers {
  font-size: 50px;
  font-weight: bold;
  word-wrap: break-word;
}

@media (min-width: 0px) and (max-width: 979px) {
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #314a96;
    padding-inline: 120px;
    width: 100%;
    color: #99b1f9;
    font-size: 18px;
    padding-block: 31px;
  }
}

@media (min-width: 979px) {
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #314a96;
    padding-inline: 120px;
    width: 100%;
    color: #99b1f9;
    font-size: 18px;
    padding-block: 31px;
  }
}

a {
  color: #99b1f9;
}

.resultTextManual {
  font-size: 20px;
  font-weight: bold;
  color: #1e1e1e;
}

::placeholder {
  color: #9b9b9b !important;
}
</style>
