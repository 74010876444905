<template>
  <Calculator msg="Welcome to Your Vue.js App"/>
</template>

<script>
import Calculator from './components/Calculator.vue'

export default {
  name: 'App',
  components: {
    Calculator
  }
}
</script>

<style>
#app {
height: 100%;
background-color: #ffffff;
overflow-x: hidden;
font-family: 'Open Sans', sans-serif;

}
html, body {
    height:100%; /*both html and body*/
    background-color: #ffffff;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    
}
body {
    margin: 0; /*reset default margin*/
    background-color: #ffffff;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
}
</style>
